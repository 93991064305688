<!-- 论坛搜索页 -->
<template>
  <div>
    <!-- 搜索历史 -->
		<div class="history"  v-if="feedbacks =='aempty'">
			<div class="top">
				<span>搜索历史</span>
				<span class="iconfont">删除</span>
			</div>
			<div class="cont">
				<div class="empty" v-if="login == 'true'">暂无搜索历史</div>
				<!-- 历史搜索内容 -->
				<div class="rich"  v-else>
					<span>大白菜</span>
					<span>小松鼠</span>
					<span>空心菜</span>
					<span>绿豆芽</span>
					<span>大番薯</span>
				</div>
		</div>
			
		</div>
		<!-- 搜索反馈页面 -->
		<div class="results" v-if="feedbacks =='anoempty'">
      <Tabs v-model="current">
        <Tab title="相关贴子" class="results_cont" >
          <div class="results_cont1" v-show="current === 0">
            <!-- 文章 -->
            <div class="article">
              <div class="title">
                兴华一老赖醉酒拒绝执法，执行干警对其采取强制拘留措施
              </div>
              <div class="message">
                <div class="left">
                  <span class='name'>暗淡行星</span>
                  <span class="read">2770 阅读</span>
                  <span class="comment">178 评论</span>
                </div>
                <div class="right">
                  35分钟前
                </div>
              </div>
            </div>
            <div class="article">
              <div class="title">
                兴华一老赖醉酒拒绝执法，执行干警对其采取强制拘留措施
              </div>
              <div class="message">
                <div class="left">
                  <span class='name'>暗淡行星</span>
                  <span class="read">2770 阅读</span>
                  <span class="comment">178 评论</span>
                </div>
                <div class="right">
                  35分钟前
                </div>
              </div>
            </div>
            <!-- 暂无更多内容了~ -->
            <div class="hint">暂无更多内容了~</div>
          </div>
        </Tab>
        <Tab title="相关用户" class = "results_cont">
          <div class="results_cont2" v-show="current === 1">
            <div class="person">
              <img src="@static/forum/tou.jpg" />
              <div class="left">
                <span class="name">老汉醉酒</span>
                <span class="sign">该用户暂未设置个性签名</span>
              </div>
              <div class="right">
                <span class="attention" v-if="attention=='flase' ">+关注</span>
                <span class="ignore" v-else>已关注</span>
              </div>
            </div>
            <!-- 暂无更多内容了~ -->
            <div class="hint">暂无更多内容了~</div>
          </div>
        </Tab>
      </Tabs>
		</div>	
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
export default {
  components: {
    Tab, Tabs
  },
  data () {
    return {
        login:"true",
        feedbacks:"anoempty",
        current: 1,
        attention:"false",
    };
  }
}

</script>
<style>
/* .van-tabs .van-tabs__wrap, .van-tabs .tabs__wrap,.van-tabs .van-tab{
  height:75px !important;
  line-height: 75px !important;
} */
</style>
<style lang='scss' scoped>
// 搜索历史
.history{
	font-size:12px;
	.top{
		display: flex;
		justify-content: space-between;
		color:rgba(102,102,102,1);
		padding:12px;
	}
	.cont{
		padding:12px;
		text{
			padding: 6px 12px;
			font-size:14px;
			font-family:PingFang SC;
			font-weight:500;
			border-radius:12px;
			margin-right:10px;
		}
		.empty{
			color:rgba(153,153,153,1);
		}
	}
}
// 反馈页面
.results{
	background:rgba(244,244,244,1);
	.results_cont{
		padding:0 12px;
		margin-top:7px;
		background:#fff;
		.results_cont1{
			.article{
        border-bottom:1px solid rgba(201,201,201,1);
        height:74px;
        .title{
          font-size:15px;
          color:#4C4C4C;
          padding-bottom: 10px;
        }
        .message{
          font-size:12px;
          color:rgba(125,125,125,1);
          display: flex;
          .left{
            width:68%;
          }
          .right{
            flex:1;
            text-align: right;
            padding-right:5px; ;
          }
        }
			}
		}
		.results_cont2{
			.person{
				height:50px;
        background:rgba(255,255,255,1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:14px;
        padding:7px 0;
        img{
          width:40px;
          height:40px;
          padding:0px 12px;
          border-radius:50%;
        }
        .left{
          flex:1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name{
            font-size:17px;
            color:#333333;
          }
          .sign{
            font-size:9px;
            color:#8C8C8C;
          }
        }
        .right{
          width:22%;
          text{
              font-size:11px;
              padding:5px 10px;
              border-radius: 16px;
            }
            .attention{
              color:#fff;
              background:rgba(255,128,0,1);
            }
            .ignore{
              border:1px solid rgba(255,128,0,1);
              color:rgba(255,128,0,1);
            }
        }
			}
		}
		//提示内容
		.hint{
			height:30px;
      font-size:11px;
      font-family:PingFang SC;
      font-weight:500;
      color:rgba(201,201,201,1);
			line-height:30px;
			background:#fff;
			text-align: center;
		}
	}
}
	
</style>